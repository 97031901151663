import React from "react"
import { graphql } from 'gatsby';
import { FaqDetail } from "../../../components/faq/detail"

const breadcrumbsData: { text: string; path: string }[] = [
  { text: `FAQ`, path: `question` },
  { text: `システム開発支援サービスに関するFAQ`, path: `question/support` },
]

const FaqTitle: string = "システム開発支援サービスに関するFAQ";

export default function FaqGraphics({ data }) {
  const { site: { siteMetadata: { faqDetail } } } = data;
  const [ faqDetails ] = faqDetail;

  return (
    <FaqDetail
      breadcrumbsData={breadcrumbsData}
      faqDetails={faqDetails.support}
      faqTitle={FaqTitle}
    />
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        faqDetail {
          support {
            details {
              answer
              question
            }
            path
          }
        }
      }
    }
  }
`